<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="变更记录" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-timeline>
                    <a-timeline-item color="green">
                        <p>完成接收</p>
                        <p> 办理人 张三 2020-10-20 12:12</p>
                    </a-timeline-item>

                    <a-timeline-item color="red">
                        <p>办理到达</p>
                        <p> 办理人 张三 2020-10-20 12:12</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>退回</p>
                        <p>原因： 样品损坏</p>
                        <p>办理人 张三 2020-10-20 12:12</p>
                    </a-timeline-item>

                </a-timeline>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close'],
        data() {
            return {
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {
                    name: '',
                    order: ''
                },
            }
        },
        mounted() {
        },
        methods: {
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            onSubmit() {
                console.log('submit!', this.form);
            },
        }
    }
</script>
<style scoped>

</style>
