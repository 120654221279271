<template>
    <div>
        <a-row type="flex" style="flex-flow: row">
            <a-col flex="250px" class="bgc">
                <div class="samplelefttit">
                    委托单列表
                </div>
                <div>
                    <a-tree :show-line="showLine" :show-icon="showIcon" :default-expanded-keys="['0-0-0', '0-0-1', '0-0-2']" @select="onSelect">
                        <a-icon slot="icon" type="carry-out"/>
                        <a-tree-node key="0-0">
                            <a-icon slot="icon" type="carry-out"/>
                            <span slot="title" style="color: #1890ff">生化类</span>
                            <a-tree-node key="0-0-0" title="辐射物质一">
                                <a-icon slot="icon" type="carry-out"/>
                                <a-tree-node key="0-0-0-0" title="辐射物质1">
                                    <a-icon slot="icon" type="carry-out"/>
                                </a-tree-node>
                                <a-tree-node key="0-0-0-1" title="辐射物质2">
                                    <a-icon slot="icon" type="carry-out"/>
                                </a-tree-node>
                                <a-tree-node key="0-0-0-2" title="辐射物质3">
                                    <a-icon slot="icon" type="carry-out"/>
                                </a-tree-node>
                            </a-tree-node>
                            <a-tree-node key="0-0-1" title="辐射物质二">
                                <a-icon slot="icon" type="carry-out"/>
                                <a-tree-node key="0-0-1-0" title="辐射物质1">
                                    <a-icon slot="icon" type="carry-out"/>
                                </a-tree-node>
                            </a-tree-node>
                            <a-tree-node key="0-0-2" title="辐射物质三">
                                <a-icon slot="icon" type="carry-out"/>
                                <a-tree-node key="0-0-2-0" title="辐射物质1">
                                    <a-icon slot="icon" type="carry-out"/>
                                </a-tree-node>
                                <a-tree-node key="0-0-2-1" title="辐射物质2">
                                    <a-icon slot="icon" type="carry-out"/>
                                    <a-icon slot="switcherIcon" type="form"/>
                                </a-tree-node>
                            </a-tree-node>
                        </a-tree-node>
                    </a-tree>


                </div>
            </a-col>
            <a-col style="flex: 1">
                <div class="layer-box">
                    <div class="layer">

                        <table class="layui-table">
                            <tr>
                                <th>样品名称</th>
                                <th>类型</th>
                                <th>样品编号</th>
                                <th>外部编号</th>
                                <th>关联编号</th>
                                <th colspan="2">项目</th>
                                <th>方法</th>
                                <th>数据类型</th>
                                <th>数值</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <div class="zhanwei"></div>
                                </th>
                                <th class="td-1">操作</th>
                            </tr>
                            <tr>
                                <td>样品1</td>
                                <td>普通样品</td>
                                <td>
                                    CC-1000000
                                </td>
                                <td>
                                    CC-1000000
                                </td>
                                <td>

                                </td>
                                <td colspan="2">
                                    蛋白质
                                </td>
                                <td>
                                    <a-select default-value="方法" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>

                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select default-value="类型" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>

                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select show-search placeholder="请选择" option-filter-prop="children" style="width: 200px" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange">
                                        <a-select-option value="1">
                                            值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            公式
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>值</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>上限</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>下限</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td></td>
                                <td>
                                    <div class="zhanwei"></div>
                                </td>
                                <td rowspan="3" class="td-1">
                                    <a-button type="link" @click="showDrawer">
                                        查看变更记录
                                    </a-button>
                                    <a-button type="link" @click="zhuangtai=true">
                                        修改状态
                                    </a-button>
                                    <a-select default-value="请选择状态" style="width: 100px" @change="handleChange" v-show="zhuangtai">
                                        <a-select-option value="jack">
                                            状态1
                                        </a-select-option>
                                        <a-select-option value="lucy">
                                            状态2
                                        </a-select-option>
                                    </a-select>
                                    <a-button type="link" @click="showbaogaoDrawer">
                                        生成报告单
                                    </a-button>

                                </td>
                            </tr>
                            <tr>
                                <td rowspan="3">样品2</td>
                                <td rowspan="3">普通样品</td>
                                <td rowspan="3">
                                    CC-1002
                                </td>
                                <td rowspan="3">
                                    CC-1002
                                </td>
                                <td rowspan="3"></td>
                                <td>金属</td>
                                <td>
                                    <a-select default-value="请选择" style="width: 120px" @change="handleChange">
                                        <a-select-option value="jack">
                                            铁
                                        </a-select-option>
                                        <a-select-option value="lucy">
                                            铜
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td rowspan="3">
                                    <div class="zhanwei"></div>
                                </td>
                                <td rowspan="3" class="td-1" style="min-height: 160px">
                                    <a-button type="link" @click="showDrawer">
                                        查看变更记录
                                    </a-button>
                                    <a-button type="link">
                                        修改状态
                                    </a-button>
                                    <a-button type="link" @click="showbaogaoDrawer">
                                        生成报告单
                                    </a-button>
                                </td>
                            </tr>
                            <tr>
                                <td><a>删除</a></td>
                                <td>
                                    铁
                                </td>
                                <td>
                                    <a-select default-value="方法" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>

                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select default-value="lucy" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>

                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select show-search placeholder="请选择" option-filter-prop="children" style="width: 200px" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange">
                                        <a-select-option value="1">
                                            值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            公式
                                        </a-select-option>

                                    </a-select>
                                </td>
                                <td>值</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>上限</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>下限</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>否</td>
                            </tr>
                            <tr>
                                <td><a>删除</a></td>
                                <td>
                                    铁
                                </td>
                                <td>
                                    <a-select default-value="方法" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>

                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select default-value="lucy" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>
                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select show-search placeholder="请选择" option-filter-prop="children" style="width: 200px" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange">
                                        <a-select-option value="1">
                                            值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            公式
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>值</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>上限</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>下限</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>否</td>
                                <td rowspan="3">
                                    <div class="zhanwei"></div>
                                </td>


                            </tr>

                            <tr>
                                <td>样品1</td>
                                <td>平行样本</td>
                                <td>
                                    CC-1000000
                                </td>
                                <td>
                                    CC-1000000
                                </td>
                                <td>
                                    CC-1000000
                                </td>

                                <td colspan="2">
                                    二氧化硫
                                </td>
                                <td>
                                    <a-select default-value="方法" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>

                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select default-value="lucy" style="width: 120px" @change="handleChange">
                                        <a-select-option value="1">
                                            数值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            布尔值
                                        </a-select-option>
                                        <a-select-option value="3">
                                            计算值
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>
                                    <a-select show-search placeholder="请选择" option-filter-prop="children" style="width: 200px" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange">
                                        <a-select-option value="1">
                                            值
                                        </a-select-option>
                                        <a-select-option value="2">
                                            公式
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td>X值</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>Y值</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>Z值</td>
                                <td>
                                    <a-input placeholder="" class="w50"/>
                                </td>
                                <td>否</td>
                                <td>
                                    <div class="zhanwei"></div>
                                </td>
                                <td rowspan="3" class="td-1">
                                    <a-button type="link" @click="showDrawer">
                                        查看变更记录
                                    </a-button>
                                    <a-button type="link">
                                        修改状态
                                    </a-button>
                                    <a-button type="link" @click="showbaogaoDrawer">
                                        生成报告单
                                    </a-button>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </a-col>
        </a-row>
        <!--创建样品分类弹窗-->


        <Jiancejilu :close="onCloseDrawer" :visible="drawer_visible"></Jiancejilu>
        <Baogaodan :close="onClosebaogaoDrawer" :visible="baogaodan_visible"></Baogaodan>

    </div>

</template>

<script>

    import Jiancejilu from '../../../components/drawer/jiancejilu/index.vue';
    import Baogaodan from '../../../components/drawer/baogaodan/index.vue';
    export default {
        props: ['visible', 'close'],
        components: {Jiancejilu, Baogaodan},
        data() {
            return {
                zhuangtai:false,
                showLine: true,
                showIcon: false,
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                baogaodan_visible: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {
                    name: '',
                    region: undefined,
                },
                formInline: {
                    user: '',
                    password: '',
                    region: undefined,
                    danwei: '',
                    people: '',
                    tel: '',
                },
                options: [
                    {
                        value: 'zhejiang',
                        label: 'Zhejiang',
                        children: [
                            {
                                value: 'hangzhou',
                                label: 'Hangzhou',
                                children: [
                                    {
                                        value: 'xihu',
                                        label: 'West Lake',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        value: 'jiangsu',
                        label: 'Jiangsu',
                        children: [
                            {
                                value: 'nanjing',
                                label: 'Nanjing',
                                children: [
                                    {
                                        value: 'zhonghuamen',
                                        label: 'Zhong Hua Men',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
        },
        methods: {
            onSelect(selectedKeys, info) {
                console.log('selected', selectedKeys, info);
            },
            onChange(value) {
                console.log(value);
            },
            handleChange(value) {
                console.log(`selected ${value}`);
            },
            handleBlur() {
                console.log('blur');
            },
            handleFocus() {
                console.log('focus');
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onClosebaogaoDrawer() {
                this.baogaodan_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showbaogaoDrawer() {
                this.baogaodan_visible = true;
            }


        },
    };
</script>

<style scoped>

    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }

    .w300{ width: 300px}

    td, th{
        color: #333;
        font-size: 14px;
        line-height: 30px;
        box-sizing: border-box;
        white-space: nowrap;
        background: #fff !important;
        }

    .layer-box{
        width: 1750px;
        /*width: 100%;*/
        overflow: hidden;
        position: relative;
        }

    .layer{
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        }

    .td-1{
        position: absolute;
        right: 0;
        z-index: 99;
        width: 350px;
        background: #ffffff;
        }

    .zhanwei{
        width: 143px;

        }

    table tr:nth-child(odd) th{
        background: #f7f8f9;}

    .ml5{ margin-left: 5px}

    .w50{ width: 50px}


</style>
